<template>
  <div class="container mb_page_container">
    <div class="row mb_calc_head">
      <div class="col-12">
        <div class="mb_logo">
            <img :src="logoUrl" class="logo">
        </div>
        <h1 class="mb_calc_title">Interactive Nutrition Menu</h1>
      </div>
    </div>  

    <div v-if="!disclaimerConfirmed" style="display: grid; place-items: center; height: 100%; padding: 0 20px;">
      <notice :brandColor="brandColor" :onConfirm="confirmDisclaimer" />
    </div>

    <div class="row mb_calc_body" v-show="disclaimerConfirmed">
      <div class="col-12 mb_table_wrapper">
        <table class="mb_table">
          <thead class="sticky-thead" v-bind:style="{background: createBackgroundString}">
            <tr>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Menu Item</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Portion</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Calories</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Total Fat (g)</span>
              </th>                            
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Saturated Fat (g)</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Trans Fat (g)</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Cholesterol</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Sodium (mg)</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Carbohydrate</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Dietary Fiber (g)</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Sugar (g)</span>
              </th>
              <th v-bind:style="{backgroundColor: createBackgroundString}">
                <span>Protein (g)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="Object.keys(this.filters).length > 0" class="mb_checks sticky-row">
              <td colspan="8">
                <div class="form-check" v-for="(value, filter_name) in this.filters" v-bind:key="filter_name">
                  <input class="form-check-input" type="checkbox" :id="filter_name" v-on:click="updateFilter" v-bind:checked="value">
                  <label class="form-check-label" :for="filter_name">
                      {{filter_name}} free
                  </label>
                </div>
              </td>
              <td colspan="3" style="text-align: right">
                <button type="button" class="btn btn-primary btn-sm btn-clear" v-on:click="clearCheckboxes">Clear</button>
              </td>
            </tr>
            <tr v-bind:class="this.show_contact_capture" v-if="this.allow_contact_leads">
              <td colspan="11">
                <p>Want special {{listedAllergens()}} Free offers?  Add your mobile or email here:</p>
                <div class="mb_exapnd_form">
                  <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="error_list">
                        <li>{{ errors[0] }}</li>
                    </ul>
                  </p>
                  <form class="form-inline">
                    <div class="form-group mb-2">
                        <label for="staticEmail2" class="sr-only">Email</label>
                        <input type="text"  class="form-control-plaintext" id="staticEmail2" v-model="contactInfo" placeholder="email or cell xxx-xxx-xxxx">
                    </div>
                    <button type="submit" class="btn btn-primary mb-2 submit-contact" v-on:click="submitContact($event)">sign up</button>
                  </form>
                </div>
              </td>
            </tr>
          </tbody>
          <b-spinner v-if="loading && !loadingCategoryId" class="d-flex justify-content-center mb-3"></b-spinner>
          <tbody v-for="category in sortedCategories" v-bind:key="category.id">
            <tr class="mb_subcategory" v-bind:key="category.id" @click="loadItems(category.id)">
              <td colspan="8">
                <h3>
                  {{category.name}}
                  <b-spinner v-if="loading && loadingCategoryId == category.id" style="font-size: initial"></b-spinner>
                </h3>
              </td>
            </tr>
            <tr class="mb_menuitem_row" 
              @click="$bvModal.show(item.id.toString()), fetchRecipeInfo(item)"
              v-for="item in category.recipes" 
              v-bind:key="item.id"
              v-bind:class="categoryStatus(item.allergens)">
              <td class="mb_item">
                <span v-if="item.mealbuilder_name">{{item.mealbuilder_name}}</span>
                <span v-else-if="item.name">{{item.name}}</span>
                <span v-else>???</span>
              </td>
              <td class="mb_portion">See more</td>
              <td class="mb_col">{{item.nutritional_preview.calories}}</td> 
              <td class="mb_col">{{item.nutritional_preview.total_fat}}</td> 
              <td class="mb_col">{{item.nutritional_preview.saturated_fat}}</td>
              <td class="mb_col">{{item.nutritional_preview.trans_fat}}</td>
              <td class="mb_col">{{item.nutritional_preview.cholesterol}}</td>
              <td class="mb_col">{{item.nutritional_preview.sodium}}</td>
              <td class="mb_col">{{item.nutritional_preview.total_carbs}}</td>
              <td class="mb_col">{{item.nutritional_preview.dietary_fiber}}</td>
              <td class="mb_col">{{item.nutritional_preview.sugars}}</td>
              <td class="mb_col">{{item.nutritional_preview.protein}}</td>
              <b-modal v-bind:value="item.id" :id="item.id.toString()"  v-bind:title="item.name" ok-only>
                <div v-if="loading_recipe">
                  <b-spinner></b-spinner>
                </div>
                <div v-else>
                  <h3>Nutrition</h3>
                  <ul>
                    <li>Portion Size: {{item.nutritional_preview.portion}}</li>
                    <li>Calories: {{item.nutritional_preview.calories}}</li>
                    <li>Total Fat: {{item.nutritional_preview.total_fat}} g</li>
                    <li>Saturated Fat: {{item.nutritional_preview.saturated_fat}} g</li>
                    <li>Trans Fat: {{item.nutritional_preview.trans_fat}} g</li>
                    <li>Cholesterol: {{item.nutritional_preview.cholesterol}}</li>
                    <li>Sodium: {{item.nutritional_preview.sodium}} mg</li>
                    <li>Carbohydrates: {{item.nutritional_preview.total_carbs}}</li>
                    <li>Dietary Fiber: {{item.nutritional_preview.dietary_fiber}} g</li>
                    <li>Sugar: {{item.nutritional_preview.sugars}} g</li>
                    <li>Protein: {{item.nutritional_preview.protein}} g</li>
                  </ul>
                  <h3>Ingredients</h3>
                  <ul>
                    <li v-for="ingredient in recipeIngredients" v-bind:key="ingredient.id">
                      {{ingredient.name}}
                    </li>
                  </ul>
                </div>
              </b-modal>
            </tr>
          </tbody>
        </table>
      </div>
    </div>  

    <div class="row mb_calc_about">
      <div class="col-12">
        <p v-if="disclaimer" v-html="disclaimer"></p>
        <p v-else>This Interactive Nutrition Menu by FoodCalc® using their restaurant analysis product MenuCalc®</p>
        <div v-if="!featureFlags?.hide_everybite_banner_footer">
          <iframe class="everybite-iframe text" src="https://foodcalc.ac-page.com/everybite-cta-box-2"></iframe>
        </div>
        <a v-else-if="!featureFlags.smartmenu_everybite_redirect" href="https://foodcalc.ac-page.com/welcome-everybite" target="_blank" @click="trackEverybiteRedirect">
          <button type="button" style="margin-left: 0 !important" class="btn btn-primary btn-sm btn-clear meet-everybite">
            Create My Profile
          </button>
        </a>
      </div>
    </div>
  </div>    
</template>

<script>
import axios from "axios";
import Notice from './Notice.vue';
import { disclaimerNotice } from "../util";

import '@/assets/legacy.scss';

export default {
  name: "SmartMenuLegacy",
  props: {
    accountId: Number,
    id: Number
  },
  data() {
    return {
      ALLERGEN_FILTERS_MAP: {
        wheat: "Wheat",
        dairy: "Milk",
        peanut: "Peanut",
        tree_nut: "Tree Nut",
        egg: "Egg",
        fish: "Fish",
        shellfish: "Shellfish",
        soy: "Soy"
      },
      menuCategories: {},
      sortedCategories: {},
      filters: {
        // TODO fix allergen model association
        Wheat: false,
        Milk: false,
        Peanut: false,
        "Tree Nut": false,
        Egg: false,
        Fish: false,
        Shellfish: false,
        Soy: false
      },
      menuItems: [],
      recipeIngredients: [],
      ingredientNames: "",
      logoUrl: "https://menucalc.com/wp-content/uploads/logo.svg",
      brandColor: "#398aca",
      backgroundColor: "#faf9f9",
      buyNowUrl: "",
      loading: true,
      loading_recipe: false,
      hideLogoIndicator: false,
      showBuyNowButton: false,
      show_contact_capture: "mb_expand",
      contactInfo: "",
      allow_contact_leads: false,
      errors: [],
      loadingCatId: null,
      disclaimer: "",
      keys: {},
      isDisclaimerConfirmed: false,
    };
  },
  components: {
    Notice,
  },
  computed: {
    createBackgroundString() {
      return `${this.brandColor}`;
    },
    disclaimerNoticeText() {
      return disclaimerNotice;
    },
    disclaimerConfirmed() {
      return !!this.getSmKeyValue('sm_disclaimer_confirmed') || this.isDisclaimerConfirmed;
    },
  },
  async created() {
    const getSetup = await axios.get(
      `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}.json`,
      {
        responseType: "json"
      }
    );

    this.setupUI(getSetup);

    const getMenu = await axios
      .get(
        `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/categories`,
        {
          responseType: "json"
        }
      )
      .then(res => {
        this.setMenu(res);
      });

    window.addEventListener("beforeunload", this.leavingPage);

    this.setupDiner();
    getMenu;
    this.isDisclaimerConfirmed = !!this.getSmKeyValue('sm_disclaimer_confirmed');
    this.loading = false;
  },
  methods: {
    async confirmDisclaimer(){
      try {
        await axios.post(`${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diners/${this.getSmKeyValue("sm_diner_id")}/confirm-disclaimer`, {
          responseType: 'json'
        });
        this.setSmKeyValue("sm_disclaimer_confirmed", true, '1y');
        this.isDisclaimerConfirmed = true
        this.$modal.hide('notice')
        axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            event_type: "allergens_notice_cleared",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
      } catch (_e) {
        // do nothing
      }
    },
    async trackEverybiteRedirect() {
      try {
        await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            event_type: "everybite_redirect",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
      } catch (e) {
        // do nothing
      }
    },
    async getMoreMenu(offset) {
      await axios
        .get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/recipes_by_category_ing.json`,
          {
            responseType: "json"
          }
        )
        .then(res => {
          this.addToMenu(res.data);
          this.categoryCount += offset;
        });
    },

    setupUI(response) {
      if (response.data.logo_url) {
        this.logoUrl = response.data.logo_url;
        this.hideLogoIndicator = true;

        if (response.data.background_color) {
          document.body.style.backgroundColor = response.data.background_color;
        }
      }

      if (response.data.brand_color) {
        this.brandColor = response.data.brand_color;
      }

      if (response.data.buy_now_url) {
        this.buyNowUrl = response.data.buy_now_url;
      }

      if (response.data.disclaimer) {
        this.disclaimer = response.data.disclaimer;
      }

      if (response.data.allow_contact_leads) {
        this.allow_contact_leads = !!response.data.allow_contact_leads;
      }

      if ("allowed_allergens" in response.data) {
        this.setFilters(response.data.allowed_allergens);
      }
    },

    async setupDiner() {
      // Set up local cookies for event tracking
      console.log("Set up Diner");

      if (!this.getSmKeyValue("sm_diner_id")) {
        const diner_cookie = await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diners`,
          {
            responseType: "json"
          }
        );
        console.log("Got Diner Cookie");
        console.log(diner_cookie);

        this.deleteSmKeyValue("sm_session_id");

        this.setSmKeyValue("sm_diner_id", diner_cookie.data.diner.authtoken, -1);

        this.setSession(diner_cookie.data.diner.authtoken);
      } else {
        this.setSession(this.getSmKeyValue("sm_diner_id"));
      }
    },

    setSmKeyValue(key, value, expireIn) {
      this.$cookies.set(
        key,
        value,
        expireIn,
        null,
        'foodcalc.com',
        true,
        "None"
      );
      this.keys[key] = value;
      localStorage.setItem(key, value);
    },

    getSmKeyValue(key) {
      try {
        return this.$cookies.get(key) || localStorage.getItem(key);
      } catch(_e) {
        return this.keys[key] || null;
      }
    },

    deleteSmKeyValue(key) {
      this.$cookies.remove(key);
      delete this.keys[key]
      localStorage.removeItem(key);
    },

    async setSession(diner_id) {
      if (!this.getSmKeyValue("sm_session_id")) {
        // diner session will expire in an hour
        const diner_session_cookie = await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diner_events?authtoken=${diner_id}&account_id=${this.accountId}`,
          {
            responseType: "json"
          }
        );
        this.setSmKeyValue(
          "sm_session_id",
          diner_session_cookie.data.diner_event.session_cookie,
          "1h"
        );
      }
    },

    setCategories(response) {
      this.menuCategories = response.data;
      this.sortedCategories = this.sortCategories(response.data);
    },

    setFilters(allergens) {
      if (!allergens || allergens.length === 0) return;

      /* eslint-disable */
      const allowedEntries = Object.entries(allergens)
        .filter(([_allergen_name, allowed]) => !!allowed)
        .map(entry => [this.ALLERGEN_FILTERS_MAP[entry[0]] || entry[0], false]);
      /* eslint-enable */

      this.filters = Object.fromEntries(allowedEntries);
    },

    sortCategories(categories) {
      const orderedByPosition = categories
        .filter(c => !!c.sorted_position)
        .sort((a, b) => a.sorted_position - b.sorted_position);

      let maxOrder = 1;
      if (orderedByPosition.length) {
        maxOrder =
          Math.max(...orderedByPosition.map(c => c.sorted_position)) + 1;
      }

      const orderedbyText = categories
        .filter(c => !c.sorted_position)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(c => {
          const res = {
            ...c,
            sorted_position: maxOrder
          };
          maxOrder++;
          return res;
        });

      const orderedCategories = [...orderedByPosition, ...orderedbyText];
      const orderedHash = {};
      orderedCategories.forEach((cat, i) => (orderedHash[i] = cat));
      return orderedHash;
    },

    setMenu(response) {
      let res = response.data;
      for (let i = 0; i < response.data.length; i++) {
        this.menuCategories[res[i].id] = res[i];
      }
      const sc = this.sortCategories(res);
      const orderedCategories = Object.values(sc);

      setTimeout(() => {
        if (orderedCategories.length) {
          this.loading = true;
          this.loadingCategoryId = orderedCategories[0].id;
        }
      }, 13);

      orderedCategories
        .map(c => () => new Promise(resolve => resolve(this.loadItems(c.id))))
        .reduce((prev, task) => {
          return prev.then(task).catch(err => {
            console.warn("err", err.message);
          });
        }, Promise.resolve());
      this.sortedCategories = orderedCategories;
    },

    addToMenu(response) {
      let cats = this.menuCategories;
      cats[response.id] = response;
      this.loading = false;
      this.loadingCategoryId = null;
      const newCategories = Object.assign({}, cats);
      this.menuCategories = newCategories;
      this.sortedCategories = Object.assign(
        {},
        this.sortCategories(Object.values(newCategories))
      );
    },

    loadItems(catId) {
      this.loading = true;
      this.loadingCategoryId = catId;
      axios
        .get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/categories/${catId}/recipes_by_category_ing.json`,
          {
            responseType: "json"
          }
        )
        .then(res => {
          this.addToMenu(res.data);
        });
    },

    clearCheckboxes() {
      Object.keys(this.filters).forEach(key => (this.filters[key] = false));
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          event_type: "allergens_cleared",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
    },

    updateFilter(event) {
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          allergen_name: event.target.id,
          event_type: this.filters[event.target.id]
            ? "allergen_unflagged"
            : "allergen_flagged",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
      this.filters[event.target.id] = !this.filters[event.target.id];
      this.emailStatus();
    },

    allergenFree(filters, allergens) {
      let status = true;

      allergens.forEach(element => {
        if (filters.includes(element)) {
          status = false;
        }
      });
      return status;
    },

    categoryStatus(allergens) {
      if (Object.values(this.filters).some(el => el)) {
        // Check to see if NONE of the allergens are in the filtered allergens
        let filters = [];
        Object.keys(this.filters).forEach(element => {
          if (this.filters[element]) {
            filters.push(element);
          }
        });

        if (this.allergenFree(filters, allergens)) {
          return "mb_row_selected";
        } else {
          return "mb_row_blured";
        }
      }
    },

    emailStatus() {
      // TODO there is a better way to do this, clicking clear filters within the select allergen window will cause an empy field to appear
      if (Object.values(this.filters).some(el => el)) {
        setTimeout(() => (this.show_contact_capture = "mb_expanded"), 50);
      } else {
        this.show_contact_capture = "mb_expand";
      }
    },

    listedAllergens() {
      let allergens = [];
      Object.keys(this.filters).forEach(element => {
        if (this.filters[element]) {
          allergens.push(element);
        }
      });
      return allergens.join(", ");
    },

    async fetchRecipeInfo(item) {
      this.loading_recipe = true;
      axios.post(
        `${this.$parent.settingsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          recipe_id: item.id,
          event_type: "more_info_clicked",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
      const getIngredientsByRecipe = await axios.get(
        `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/recipes/${item.id}/ingredients.json/`,
        {
          responseType: "json"
        }
      );
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          ingredient_id: item.id,
          event_type: "recipe_select",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
      this.loading_recipe = false;
      console.log(getIngredientsByRecipe);
      this.recipeIngredients = getIngredientsByRecipe.data;
    },
    submitContact(event) {
      event.preventDefault();
      this.checkContact();
      if (this.errors.length < 1) {
        axios.post(
          `${this.$parent.settingsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            contact_info: this.contactInfo,
            event_type: "contact_submitted",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );

        // TODO add thank you for submitting contact info
        // TODO prevent more email pop ups from happening
        this.contactInfo = "";
        this.show_contact_capture = "mb_expand";
      }
    },
    checkContact() {
      this.errors = [];
      const emailRegex = /[a-zA-Z0-9_.\]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
      const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      if (this.contactInfo.length === 0) {
        this.errors.push("Contact form is empty");
      } else if (this.contactInfo.includes("@")) {
        // The testing server side to see email vs phone is checking for @ symbol
        // Should maintain consistency
        if (!emailRegex.test(this.contactInfo)) {
          this.errors.push("Email format is invalid");
        }
      } else {
        if (!phoneRegex.test(this.contactInfo)) {
          this.errors.push("this is not a valid phone number");
        }
      }
    },
    leavingPage() {
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          contact_info: this.contactInfo,
          event_type: "leaving_page",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
    }
  }
};
</script>

<style scoped>
</style>