<template>
  <div v-if="loaded && !deprecated">
    <SmartMenu v-if="flagEnabled('INTERACTIVE_DISPLAY')" :accountId="accountId" :featureFlags="this.featureFlags" />
    <SmartMenuLegacy v-if="!flagEnabled('INTERACTIVE_DISPLAY')" :accountId="accountId"/>
  </div>
  <div v-else-if="deprecated">
    <NotFound/>
  </div>
</template>

<script>
import SmartMenu from './components/SmartMenu.vue'
import SmartMenuLegacy from './components/SmartMenuLegacy.vue'
import NotFound from './components/NotFound.vue'
import axios from 'axios'

export const FEATURE_FLAGS = {
  INTERACTIVE_DISPLAY: "interactive_display",
  INTERACTIVE_DISPLAY_SEARCH: "interactive_display_search",
  INTERACTIVE_DISPLAY_HIDE_NUTRIENTS: "interactive_display_hide_nutrients",
  SMARTMENU_ENABLED: "smartmenu_enabled",
  SMARTMENU_DEPRECATION: "smartmenu_deprecation",
}

export default {
  name: 'App',
  components: {
    SmartMenu,
    SmartMenuLegacy,
    NotFound
  },
  data() {
    return {
      accountId: 1,
      settingsEndpoint: process.env.VUE_APP_SETTINGS_ENDPOINT,
      analyticsEndpoint: process.env.VUE_APP_ANALYTICS_ENDPOINT,
      featureFlags: {},
      loaded: false,
      deprecated: false,
    }
  },
  methods: {
    flagEnabled(flag) {
      return this.featureFlags[FEATURE_FLAGS[flag]]
    }
  },
  async created() {
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      if (params.get("accountId")){
        this.accountId = parseInt(params.get("accountId"));
        switch (this.accountId) {
            // Turning Point
          case 3:
            window.location.href = 'https://everybite.com/widget/b55c094a';
            break;

            // CoreLife Eatery
          case 332:
            window.location.href = 'https://everybite.com/widget/6321ac4c';
            break;

            // Postino Wine Cafe
          case 9170:
            window.location.href = 'https://everybite.com/widget/7d6e5b3d';
            break;

            // Frickers
          case 2917:
            window.location.href = 'https://everybite.com/widget/1d88bfae';
            break;

            // Twisted Fresh
          case 2145:
            window.location.href = 'https://everybite.com/widget/7965fdd2';
            break;
        }
      }

      try {
        const {data} = await axios.get(
          `${this.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/flags`,
          {
            responseType: "json"
          }
        )
        this.featureFlags = data

        this.loaded = true
        this.deprecated = this.flagEnabled("SMARTMENU_DEPRECATION") && !this.flagEnabled("SMARTMENU_ENABLED")
      } catch (e) {
        this.loaded = false
        this.deprecated = true
      }
  }
}
</script>
