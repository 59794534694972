<template>
  <div class="layout">
    <div class="email_box">
      <div class="row ">
        <div class="col-12 mb_calc_about">
          <div v-if="buyNowUrl !== ''" class="full-center-content mt-1">
            <b-button size="lg" v-if="isMobile" variant="success"
              :style="`background-color: ${createBackgroundString}; border-color: ${createBackgroundString}`"
              @click="buyNow()">
              Order Online
            </b-button>
          </div>
          <p v-if="disclaimer" v-html="disclaimer"></p>
          <p v-else>This Interactive Nutrition Menu by FoodCalc® using their restaurant analysis product MenuCalc®</p>
          <div v-if="!featureFlags?.hide_everybite_banner_footer">
            <iframe class="everybite-iframe text" src="https://foodcalc.ac-page.com/everybite-cta-box-2"></iframe>
          </div>
          <a v-else-if="!featureFlags.smartmenu_everybite_redirect" href="https://foodcalc.ac-page.com/welcome-everybite" target="_blank" @click="trackEverybiteRedirect">
            <button type="button" style="margin-left: 0 !important" class="btn btn-primary btn-sm btn-clear meet-everybite">
              Create My Profile
            </button>
          </a>
        </div>
      </div>
    </div>
    <aside class="filter_box">
      <div class="facet_area" v-if="isDisclaimerConfirmed">
        <div class="full-center-content"><img v-if="isMobile" :src="logoUrl" class="logo" /></div>
        <div class="allergens">
          <div>
            <h3 v-if="!isMobile">Menu filters</h3>
          </div>
          <div>
            <button class="menu-filter-button" @click="menuFiltersExpanded = !menuFiltersExpanded">
              <h3 style="margin-left: 12px;" v-if="isMobile">Menu filters</h3>
              <caret v-if="isMobile" :parentExpanded="!menuFiltersExpanded" />
            </button>
            <ul v-show="menuFiltersExpanded">
              <li v-for="(value, filter_name) in this.filters" v-bind:key="filter_name">
                <input :class="{ 'checked': value }" type="checkbox" :id="filter_name" v-on:click="updateFilter">
                <label :for="filter_name">
                  {{ filter_name }}
                </label>
              </li>
              <li v-if="!isMobile" style="margin-top: 24px; margin-left: 25%;">
                <input style="border: 0; background: white; height: 0; width: 0; cursor: pointer;" type="button"
                  id="clear_filter" @click="clearCheckboxes">
                <label for="clear_filter"> Clear</label>
              </li>
            </ul>
            <form v-if="isMobile && menuFiltersExpanded && allow_contact_leads && !contactCaptured">
              <div class="email-wrapper">
                <label class="email-label" for="staticEmail2">Sign-up for menu offers near you!</label>
                <input v-model="contactInfo" type="text" id="staticEmail2" placeholder="email or cell xxx-xxx-xxxx"
                  class="form-control-plaintext" />
                <button v-on:click="submitContact($event)" type="submit" class="btn btn-primary" :style="`background-color: ${brandColor}`">Sign up</button>
              </div>
            </form>
            <div v-if="isMobile && contactCaptured">
              Thank you for signing up!
            </div>
            <div class="filters-string" v-show="!menuFiltersExpanded && isMobile && filteredString">Filtering by: {{
              filteredString }}</div>
          </div>
          <div v-if="!featureFlags?.hide_everybite_banner" style="margin-left: 7px">
            <iframe class="everybite-iframe square" src="https://foodcalc.ac-page.com/everybite-cta-box"></iframe>
          </div>
        </div>
      </div>
      <div v-if="buyNowUrl !== ''" class="mt-4" style="text-align: center">
        <b-button size="lg" v-if="!isMobile" variant="success"
          :style="`background-color: ${createBackgroundString}; border-color: ${createBackgroundString}`"
          @click="buyNow()">
          Order Online
        </b-button>
      </div>
      <form v-if="!isMobile && allow_contact_leads && !contactCaptured">
        <div class="email-wrapper">
          <label class="email-label" for="staticEmail2">Sign-up for menu offers near you!</label>
          <input v-model="contactInfo" type="text" id="staticEmail2" placeholder="email or cell xxx-xxx-xxxx" class="form-control-plaintext" />
          <button type="submit" class="btn btn-primary" v-on:click="submitContact($event)"
            :style="`background-color: ${brandColor}; border-color: ${brandColor}`">Sign up</button>
        </div>
      </form>
      <div v-if="contactCaptured" style="display: flex; justify-content: center; margin-top: 12px;">
        Thank you for signing up!
      </div>
    </aside>
    <div class="search-field" v-if="featureFlags.interactive_display_search">
      <div class="search-container">
        <form class="no-submit" @submit.prevent.stop="handleSearchSubmit">
          <!-- Add this for debouncing -->
          <!-- @keypress="handleSearchKeypress"
              @input="handleSearch" -->
          <input class="no-submit" :class="searchLoading ? 'background-loading' : 'background-hourglass'" type="search"
            placeholder="Want to exclude an ingredient? Search for it here....." v-model="searchQuery" />
        </form>
      </div>
    </div>
    <div :style="{gridRowStart: featureFlags.interactive_display_search ? 3 : 2}" class="nutritional_info">
      <div v-if="!disclaimerConfirmed" style="display: grid; place-items: center; height: 100%; padding: 0 20px;">
        <notice :brandColor="brandColor" :onConfirm="confirmDisclaimer" />
      </div>
      <table v-show="disclaimerConfirmed" class="no-tables">
        <thead>
          <tr>
            <th class="menu-item"> <span>Menu Item</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Calories</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Total Fat</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Sat Fat</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Trans Fat</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Chol.</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Sodium</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Carbs</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Fiber</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Sugar</span></th>
            <th v-if="!hideNutrients" class="nvalues"> <span>Protein</span></th>
          </tr>
        </thead>
        <tbody v-for="category in sortedCategories" v-bind:key="category.id">
          <tr class="category" v-if="!isMobile" @click="loadItems(category.id)">
            <td :colspan="hideNutrients ? 1 : 10">
              <h3>{{ category.name }}<b-spinner small v-if="loading && loadingCategoryId == category.id"
                  style="font-size: 16px;"></b-spinner></h3>
            </td>
          </tr>
          <div v-if="isMobile" @click="loadItems(category.id)">
            <h3>{{ category.name }}<b-spinner small v-if="loading && loadingCategoryId == category.id"
                style="font-size: 16px;"></b-spinner></h3>
          </div>
          <tr @click="isMobile ? (fetchRecipeInfo(item)) : ($bvModal.show(item.id.toString()), fetchRecipeInfo(item))"
            class="menu-items" v-for="item in category.recipes" v-bind:key="item.id"
            v-bind:class="categoryStatus(item.allergens, item.id)">
            <div v-if="isMobile" class="mobile-menu-item">
              <span v-if="item.mealbuilder_name">{{ item.mealbuilder_name }}</span>
              <span v-else-if="item.name">{{ item.name }}</span>
              <span v-else>???</span>
              <div>
                <span></span>
              </div>
              <div>
                <caret :parent-expanded="!visibleRecipes[item.id]" />
              </div>
            </div>
            <td v-if="!isMobile" data-nutr-info="Menu Item">
              <span v-if="item.mealbuilder_name">{{ item.mealbuilder_name }}</span>
              <span v-else-if="item.name">{{ item.name }}</span>
              <span v-else>???</span>
              <div>
                <span></span>
              </div>
            </td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Calories">{{ item.nutritional_preview.calories
            }}</td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Total Fat (g)">{{
              item.nutritional_preview.total_fat }}<span class="uom"> g</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Sat Fat (g)">{{
              item.nutritional_preview.saturated_fat }}<span class="uom"> g</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Trans Fat (g)">{{
              item.nutritional_preview.trans_fat }}<span class="uom"> g</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Cholesterol">{{
              item.nutritional_preview.cholesterol }}</td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Sodium (mg)">{{
              item.nutritional_preview.sodium }}<span class="uom"> mg</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Carbs">{{ item.nutritional_preview.total_carbs
            }}</td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Fiber (g)">{{
              item.nutritional_preview.dietary_fiber }}<span class="uom"> g</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Sugar (g)">{{ item.nutritional_preview.sugars
            }}<span class="uom"> g</span></td>
            <td v-if="(!isMobile || visibleRecipes[item.id]) && !hideNutrients" data-nutr-info="Protein (g)">{{
              item.nutritional_preview.protein }}<span class="uom"> g</span></td>
            <div v-if="(isMobile && visibleRecipes[item.id])">
              <h3>Ingredients</h3>
              <ul>
                <li v-for="ingredient in recipeIngredients" v-bind:key="ingredient.id">
                  {{ ingredient.name }}
                </li>
              </ul>
            </div>
            <b-modal v-bind:value="item.id" :id="item.id.toString()" v-bind:title="item.mealbuilder_name ? item.mealbuilder_name : item.name ? item.name : '???'">
              <template #modal-footer="{ ok }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button style="background-color: var(--desktop-header-bg-color); border: 0;" variant="secondary" @click="ok()">
                  OK
                </b-button>
                <!-- Button with custom close trigger value -->
              </template>
              <div v-if="loading_recipe">
                <b-spinner></b-spinner>
              </div>
              <div v-else>
                <h3 v-if="!hideNutrients">Nutrition</h3>
                <ul v-if="!hideNutrients">
                  <li>Portion Size: {{ item.nutritional_preview.portion }}</li>
                  <li>Calories: {{ item.nutritional_preview.calories }}</li>
                  <li>Total Fat: {{ item.nutritional_preview.total_fat }} g</li>
                  <li>Saturated Fat: {{ item.nutritional_preview.saturated_fat }} g</li>
                  <li>Trans Fat: {{ item.nutritional_preview.trans_fat }} g</li>
                  <li>Cholesterol: {{ item.nutritional_preview.cholesterol }}</li>
                  <li>Sodium: {{ item.nutritional_preview.sodium }} mg</li>
                  <li>Carbohydrates: {{ item.nutritional_preview.total_carbs }}</li>
                  <li>Dietary Fiber: {{ item.nutritional_preview.dietary_fiber }} g</li>
                  <li>Sugar: {{ item.nutritional_preview.sugars }} g</li>
                  <li>Protein: {{ item.nutritional_preview.protein }} g</li>
                </ul>
                <h3>Ingredients</h3>
                <ul>
                  <li v-for="ingredient in recipeIngredients" v-bind:key="ingredient.id">
                    {{ ingredient.name }}
                  </li>
                </ul>
              </div>
            </b-modal>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!isMobile" class="title-area">
      <img :src="logoUrl" class="logo">
      <h4>Interactive Nutrition & Allergen Guide</h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Caret from './Caret.vue';
import Notice from './Notice.vue';
import { disclaimerNotice } from "../util";
import debounce from 'lodash.debounce'

import '@/assets/main.scss';

export default {
  name: "SmartMenu",
  props: {
    accountId: Number,
    id: Number,
    featureFlags: {
      default: () => ({}),
    }
  },
  components: { Caret, Notice },
  data() {
    return {
      contactCaptured: false,
      searchQuery: "",
      ALLERGEN_FILTERS_MAP: {
        wheat: "Wheat",
        dairy: "Milk",
        peanut: "Peanut",
        tree_nut: "Tree Nut",
        egg: "Egg",
        fish: "Fish",
        shellfish: "Shellfish",
        soy: "Soy"
      },
      menuCategories: {},
      sortedCategories: {},
      filters: {
        // TODO fix allergen model association
        Wheat: false,
        Milk: false,
        Peanut: false,
        "Tree Nut": false,
        Egg: false,
        Fish: false,
        Shellfish: false,
        Soy: false
      },
      menuItems: [],
      recipeIngredients: [],
      ingredientNames: "",
      logoUrl: "https://menucalc.com/wp-content/uploads/logo.svg",
      brandColor: "#398aca",
      backgroundColor: "#faf9f9",
      buyNowUrl: "",
      loading: true,
      loading_recipe: false,
      hideLogoIndicator: false,
      showBuyNowButton: false,
      show_contact_capture: "mb_expand",
      contactInfo: "",
      allow_contact_leads: false,
      errors: [],
      loadingCatId: null,
      disclaimer: "",
      windowWidth: window.innerWidth,
      visibleRecipes: {},
      cachedRecipeIngredients: {},
      keys: {},
      isDisclaimerConfirmed: false,
      menuFiltersExpanded: false,
      excludedIdsFromSearch: [],
      searchLoading: false,
      searched: false,
      hideNutrientsForSmartmenu: false
    };
  },
  computed: {
    createBackgroundString() {
      return `${this.brandColor}`;
    },
    filtered() {
      return Object.fromEntries(Object.entries(this.filters).filter(([, value]) => value));
    },
    filteredString() {
      return Object.keys(this.filtered).join(", ");
    },
    isMobile() {
      return this.windowWidth < 601;
    },
    disclaimerNoticeText() {
      return disclaimerNotice;
    },
    disclaimerConfirmed() {
      return !!this.getSmKeyValue('sm_disclaimer_confirmed') || this.isDisclaimerConfirmed;
    },
    hideNutrients() {
      return this.hideNutrientsForSmartmenu;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    this.menuFiltersExpanded = !this.isMobile
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === "" || this.searchQuery.length < 2) {
        this.excludedIdsFromSearch = []
        this.searched = false
      }
    }
  },
  async created() {
    this.appendStylesheet()
    const getSetup = await axios.get(
      `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}.json`,
      {
        responseType: "json"
      }
    );

    this.setupUI(getSetup);

    const getMenu = await axios
      .get(
        `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/categories`,
        {
          responseType: "json"
        }
      )
      .then(res => {
        this.setMenu(res);
      });

    window.addEventListener("beforeunload", this.leavingPage);

    this.setupDiner();
    getMenu;
    this.isDisclaimerConfirmed = !!this.getSmKeyValue('sm_disclaimer_confirmed');
    this.loading = false;
  },
  methods: {
    handleSearchSubmit() {
      this.handleSearchQuery()
      this.searchLoading = false
    },
    handleSearchKeypress() {
      this.searchLoading = true
      this.excludedIdsFromSearch = []
    },
    async handleSearchQuery() {
      if (this.searchQuery === "") {
        this.excludedIdsFromSearch = []
        return
      }
      // REFACTOR THIS TO SETTINGS ENDPOINT
      try {
        const { data } = await axios.get(this.$parent.analyticsEndpoint +
          '/api/smart_menu/v1/accounts/recipes-filter?' + new URLSearchParams({
            filter: encodeURIComponent(this.searchQuery),
            accountId: this.accountId
          }).toString()
        )
        this.excludedIdsFromSearch = [...data?.excluded_recipes] || []
        this.searchLoading = false
        this.searched = true
      } catch (e) {
        this.excludedIdsFromSearch = []
        this.searchLoading = false
      }
    },
    handleSearch: debounce(function () {
      this.handleSearchQuery()
    }, 1000),
    buyNow() {
      window.open(this.buyNowUrl)
      axios.post(`${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`, {
        event_type: 'buy_now_clicked',
        session_cookie: this.getSmKeyValue("sm_session_id"),
        account_id: this.accountId
      })
    },
    async confirmDisclaimer() {
      try {
        await axios.post(`${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diners/${this.getSmKeyValue("sm_diner_id")}/confirm-disclaimer`, {
          responseType: 'json'
        });
        this.setSmKeyValue("sm_disclaimer_confirmed", true, '1y');
        this.isDisclaimerConfirmed = true
        this.$modal.hide('notice')
        axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            event_type: "allergens_notice_cleared",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
      } catch (_e) {
        console.warn('JS blocked, but continuing')
        this.setSmKeyValue("sm_disclaimer_confirmed", true, '1y');
        this.isDisclaimerConfirmed = true
        this.$modal.hide('notice')
      }
    },
    async trackEverybiteRedirect() {
      try {
        await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            event_type: "everybite_redirect",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
      } catch (e) {
        // do nothing
      }
    },
    appendStylesheet() {
      let file = document.createElement('link');
      file.rel = 'stylesheet';
      file.href = `${process.env.NODE_ENV === 'development' ? this.$parent.analyticsEndpoint : this.$parent.settingsEndpoint}/api/smart_menu/v1/stylesheet?accountId=${this.accountId}`
      document.head.appendChild(file)
    },
    markVisible(item) {
      this.visibleRecipes[item.id] = !this.visibleRecipes[item.id]
    },
    onResize() {
      this.windowWidth = window.innerWidth
      this.menuFiltersExpanded = !this.isMobile
    },
    async getMoreMenu(offset) {
      await axios
        .get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/recipes_by_category_ing.json`,
          {
            responseType: "json"
          }
        )
        .then(res => {
          this.addToMenu(res.data);
          this.categoryCount += offset;
        });
    },

    setupUI(response) {
      if (response.data.logo_url) {
        this.logoUrl = response.data.logo_url;
        this.hideLogoIndicator = true;
        if (response.data.background_color) {
          document.body.style.backgroundColor = response.data.background_color;
        }
      }

      if (response.data.brand_color) {
        this.brandColor = response.data.brand_color;
      }

      if (response.data.buy_now_url) {
        this.buyNowUrl = response.data.buy_now_url;
      }

      if (response.data.disclaimer) {
        this.disclaimer = response.data.disclaimer;
      }

      if (response.data.allow_contact_leads) {
        this.allow_contact_leads = !!response.data.allow_contact_leads;
      }

      if ("allowed_allergens" in response.data) {
        this.setFilters(response.data.allowed_allergens);
      }

      this.hideNutrientsForSmartmenu = response.data.show_nutrients_on_smartmenu === false || false
    },

    async setupDiner() {
      // Set up local cookies for event tracking
      console.log("Set up Diner");

      if (!this.getSmKeyValue("sm_diner_id")) {
        const diner_cookie = await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diners`,
          {
            responseType: "json"
          }
        );
        console.log("Got Diner Cookie");
        console.log(diner_cookie);

        this.deleteSmKeyValue("sm_session_id");

        this.setSmKeyValue("sm_diner_id", diner_cookie.data.diner.authtoken, -1);

        this.setSession(diner_cookie.data.diner.authtoken);
      } else {
        this.setSession(this.getSmKeyValue("sm_diner_id"));
      }
    },

    setSmKeyValue(key, value, expireIn) {
      try {
        this.$cookies.set(
          key,
          value,
          expireIn,
          null,
          'foodcalc.com',
          true,
          "None"
        );
        this.keys[key] = value;
        localStorage.setItem(key, value);
      } catch (e) {
        // nothing. should not fail.
      }
    },

    getSmKeyValue(key) {
      try {
        return this.$cookies.get(key) || localStorage.getItem(key);
      } catch (_e) {
        return this.keys[key] || null;
      }
    },

    deleteSmKeyValue(key) {
      try {
        this.$cookies.remove(key);
        localStorage.removeItem(key);
        delete this.keys[key]
      } catch (e) {
        // nothing. should not fail.
      }
    },

    async setSession(diner_id) {
      if (!this.getSmKeyValue("sm_session_id")) {
        // diner session will expire in an hour
        const diner_session_cookie = await axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/diner_events?authtoken=${diner_id}&account_id=${this.accountId}`,
          {
            responseType: "json"
          }
        );
        this.setSmKeyValue(
          "sm_session_id",
          diner_session_cookie.data.diner_event.session_cookie,
          "1h"
        );
      }
    },

    setCategories(response) {
      this.menuCategories = response.data;
      this.sortedCategories = this.sortCategories(response.data);
    },

    setFilters(allergens) {
      if (!allergens || allergens.length === 0) return;

      /* eslint-disable */
      const allowedEntries = Object.entries(allergens)
        .filter(([_allergen_name, allowed]) => !!allowed)
        .map(entry => [this.ALLERGEN_FILTERS_MAP[entry[0]] || entry[0], false]);
      /* eslint-enable */

      this.filters = Object.fromEntries(allowedEntries);
    },

    sortCategories(categories) {
      const orderedByPosition = categories
        .filter(c => !!c.sorted_position)
        .sort((a, b) => a.sorted_position - b.sorted_position);

      let maxOrder = 1;
      if (orderedByPosition.length) {
        maxOrder =
          Math.max(...orderedByPosition.map(c => c.sorted_position)) + 1;
      }

      const orderedbyText = categories
        .filter(c => !c.sorted_position)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(c => {
          const res = {
            ...c,
            sorted_position: maxOrder
          };
          maxOrder++;
          return res;
        });

      const orderedCategories = [...orderedByPosition, ...orderedbyText];
      const orderedHash = {};
      orderedCategories.forEach((cat, i) => (orderedHash[i] = cat));

      if (Object.keys(this.visibleRecipes).length === 0) {
        this.visibleRecipes = orderedCategories.reduce((acc, cat) => {
          cat.recipes.forEach(recipe => {
            acc[recipe.id] = false;
          })
          return acc;
        }, {})
      }

      return orderedHash;
    },

    setMenu(response) {
      let res = response.data;
      for (let i = 0; i < response.data.length; i++) {
        this.menuCategories[res[i].id] = res[i];
      }
      const sc = this.sortCategories(res);
      const orderedCategories = Object.values(sc);

      setTimeout(() => {
        if (orderedCategories.length) {
          this.loading = true;
          this.loadingCategoryId = orderedCategories[0].id;
        }
      }, 13);

      orderedCategories
        .map(c => () => new Promise(resolve => resolve(this.loadItems(c.id))))
        .reduce((prev, task) => {
          return prev.then(task).catch(err => {
            console.warn("err", err.message);
          });
        }, Promise.resolve());
      this.sortedCategories = orderedCategories;
    },

    addToMenu(response) {
      let cats = this.menuCategories;
      cats[response.id] = response;
      this.loading = false;
      this.loadingCategoryId = null;
      const newCategories = Object.assign({}, cats);
      this.menuCategories = newCategories;
      this.sortedCategories = Object.assign(
        {},
        this.sortCategories(Object.values(newCategories))
      );
    },

    loadItems(catId) {
      this.loading = true;
      this.loadingCategoryId = catId;
      axios
        .get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/accounts/${this.accountId}/categories/${catId}/recipes_by_category_ing.json`,
          {
            responseType: "json"
          }
        )
        .then(res => {
          this.addToMenu(res.data);
        });
    },

    clearCheckboxes() {
      Object.keys(this.filters).forEach(key => (this.filters[key] = false));
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          event_type: "allergens_cleared",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
    },

    updateFilter(event) {
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          allergen_name: event.target.id,
          event_type: this.filters[event.target.id]
            ? "allergen_unflagged"
            : "allergen_flagged",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
      this.filters[event.target.id] = !this.filters[event.target.id];
      this.emailStatus();
    },

    allergenFree(filters, allergens) {
      let status = true;

      allergens.forEach(element => {
        if (filters.includes(element)) {
          status = false;
        }
      });
      return status;
    },

    categoryStatus(allergens, itemId) {
      if (Object.values(this.filters).some(el => el) || this.searchQuery !== "") {
        let shouldBlur = false;
        let filters = [];
        Object.keys(this.filters).forEach(element => {
          if (this.filters[element]) {
            filters.push(element);
          }
        });

        if (filters.length === 0 && !this.searched) return

        // check filters
        shouldBlur = filters.filter(x => allergens.includes(x)).length > 0

        if (shouldBlur) {
          return "mb_row_blured";
        }

        if (this.searched) {
          shouldBlur = this.excludedIdsFromSearch.includes(itemId)
        }

        if (shouldBlur) {
          return "mb_row_blured";
        }

        return "mb_row_selected";

      }
    },

    emailStatus() {
      // TODO there is a better way to do this, clicking clear filters within the select allergen window will cause an empy field to appear
      if (Object.values(this.filters).some(el => el)) {
        setTimeout(() => (this.show_contact_capture = "mb_expanded"), 50);
      } else {
        this.show_contact_capture = "mb_expand";
      }
    },

    listedAllergens() {
      let allergens = [];
      Object.keys(this.filters).forEach(element => {
        if (this.filters[element]) {
          allergens.push(element);
        }
      });
      return allergens.join(", ");
    },

    async fetchRecipeInfo(item) {
      this.loading_recipe = true;
      if (this.isMobile) {
        !this.visibleRecipes[item.id] && axios.post(
          `${this.$parent.settingsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            recipe_id: item.id,
            event_type: "more_info_clicked",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
        const getIngredientsByRecipe = !this.visibleRecipes[item.id] && await axios.get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/recipes/${item.id}/ingredients-with-recipe-nutritions.json/`,
          {
            responseType: "json"
          }
        );
        !this.visibleRecipes[item.id] && axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            ingredient_id: item.id,
            event_type: "recipe_select",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
        this.loading_recipe = false;
        this.recipeIngredients = getIngredientsByRecipe.data.items;
        if (this.isMobile) {
          this.markVisible(item)
        }
        this.cachedRecipeIngredients[item.id] = getIngredientsByRecipe.data.items
      } else {
        axios.post(
          `${this.$parent.settingsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            recipe_id: item.id,
            event_type: "more_info_clicked",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
        const getIngredientsByRecipe = await axios.get(
          `${this.$parent.settingsEndpoint}/api/mealbuilder/v1/recipes/${item.id}/ingredients-with-recipe-nutritions.json/`,
          {
            responseType: "json"
          }
        );
        axios.post(
          `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            ingredient_id: item.id,
            event_type: "recipe_select",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );
        this.loading_recipe = false;
        this.recipeIngredients = getIngredientsByRecipe.data.items;
        if (this.isMobile) {
          this.markVisible(item)
        }
        this.cachedRecipeIngredients[item.id] = getIngredientsByRecipe.data.items
      }

    },
    submitContact(event) {
      event.preventDefault();
      this.checkContact();
      if (this.errors.length < 1) {
        axios.post(
          `${this.$parent.settingsEndpoint}/api/smart_menu/v1/menu_events`,
          {
            contact_info: this.contactInfo,
            event_type: "contact_submitted",
            session_cookie: this.getSmKeyValue("sm_session_id"),
            account_id: this.accountId
          }
        );

        // TODO add thank you for submitting contact info
        // TODO prevent more email pop ups from happening
        this.contactInfo = "";
        this.show_contact_capture = "mb_expand";
        this.contactCaptured = true;
      }
    },
    checkContact() {
      this.errors = [];
      const emailRegex = /[a-zA-Z0-9_.\]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
      const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      if (this.contactInfo.length === 0) {
        this.errors.push("Contact form is empty");
      } else if (this.contactInfo.includes("@")) {
        // The testing server side to see email vs phone is checking for @ symbol
        // Should maintain consistency
        if (!emailRegex.test(this.contactInfo)) {
          this.errors.push("Email format is invalid");
        }
      } else {
        if (!phoneRegex.test(this.contactInfo)) {
          this.errors.push("this is not a valid phone number");
        }
      }
    },
    leavingPage() {
      axios.post(
        `${this.$parent.analyticsEndpoint}/api/smart_menu/v1/menu_events`,
        {
          contact_info: this.contactInfo,
          event_type: "leaving_page",
          session_cookie: this.getSmKeyValue("sm_session_id"),
          account_id: this.accountId
        }
      );
    }
  }
};
</script>
