<template>
    <div class="notice">
      <button :style="{backgroundColor: brandColor, border: brandColor}" class="notice-button" @click="$modal.show('notice')">
        To get started, click here to read the nutrition and allergen notice
      </button>
  
      <modal
        width="85%"
        :max-height="600"
        :maxWidth="1400"
        height="auto"
        :scrollable="true"
        name="notice"
      >
        <div class="disclaimer-text">{{ disclaimerNoticeText }}</div>
        <button :style="{backgroundColor: brandColor, border: brandColor}" class="confirm-notice-button" @click="confirmDisclaimer && onConfirm()">
          I agree to the nutrition and allergen notice while using SmartMenu.
        </button>
      </modal>
    </div>
  </template>
  
  <script>
  import { disclaimerNotice } from "../util";
  
  export default {
    computed: {
      disclaimerNoticeText() {
        return disclaimerNotice;
      },
    },
    props: {
      onConfirm: {
        type: Function,
        default: () => {},
      },
      brandColor: {
        type: String,
        default: "#398aca",
      }
    },
    methods: {
      confirmDisclaimer() {
        this.$modal.hide("notice");
      },
    },
  };
  </script>
  
  <style scoped>
  .notice {
      display: grid;
      place-items: center;
      width: 100%;
      margin: 0 0 40px;
  }
  
  .notice-button {
      background-color: #398aca;
      border: 1px solid #398aca;
      border-radius: 0;
      color: #fff;
      font-size: 1rem;
      font-weight: 900;
      letter-spacing: 3px;
      padding: 1rem;
      text-transform: uppercase;
  }
  
  .confirm-notice-button {
      background-color: #398aca;
      border: 1px solid #398aca;
      border-radius: 0;
      color: #fff;
      font-size: 1rem;
      font-weight: 900;
      letter-spacing: 3px;
      padding: 1rem;
      text-transform: uppercase;
      width: 100%;
  }
  
  .disclaimer-text {
    padding: 24px;
    white-space: pre-line;
  }
  </style>